@import url("https://fonts.googleapis.com/css2?family=Italiana&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@200&family=Roboto+Mono&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/**************************************************************** GENERAL */

.app {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.dark {
  background: black;
  color: #ffffff;
}

.light {
  background: #fff;
  color: #333;
}

.link {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: black;
}

/* unvisited link */
.link:link {
  text-decoration: none;
}

/* visited link */
.link:visited {
  text-decoration: none;
}

/* mouse over link */
.link:hover {
  text-decoration: none;
  cursor: pointer;
  font-weight: bolder;
  font-weight: 900;
}

/* selected link */
.link:active {
  color: #9e9e9e;
  text-decoration: underline;
}

.link.dark {
  color: #ffffff;
}

a {
  color: #9e9e9e;
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
}

.Navigation {
  display: flex;
  flex-direction: row;
  height: 80vh;
}

.Footer {
  height: 10vh;
}

.App-section {
  width: 100vw;
  height: 100%;
  min-height: 90vh;
}

.Section-title {
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding-top: 20vh;
}

.Section-subtitle {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding-top: 10vh;
}

.Section-title.work {
  margin-bottom: 0.5rem;
}
/****************************************************************  PRELOADER */

.preLoader {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
}

/****************************************************************  NAVBAR & HEADER */

.Navburger {
  display: none;
}

.header-display {
  display: flex;
  justify-content: right;
  width: 90vw;
}

.Navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.allNavbar {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  align-items: center;
  position: fixed;
  background-color: white;
  z-index: 200;
  padding-top: 0.5rem;
  height: 14vh;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.allNavbar.dark {
  background-color: black;
}

.menu {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  text-align: left;
  position: absolute;
  color: #fff;
  left: 50%;
  margin-top: -6vw;
  margin-left: -75vw;
  transform: rotate(-90deg);
  font-size: 12rem;
  letter-spacing: 0.4em;
}

.socials-dark {
  filter: invert(1);
  width: 1rem;
  padding: 0.8rem;
}

.socials-light {
  filter: invert(0);
  width: 1rem;
  padding: 0.8rem;
}

.LT-logo-dark {
  height: 100%;
  cursor: pointer;
}

.LT-logo-light {
  height: 100%;
  filter: brightness(0);
  transform: scale(1.3);
  cursor: pointer;
}

/**************************************************************** HOME SECTION */

.homeName {
  background: linear-gradient(90deg, #abbaee -2.06%, #387672 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.App-homepage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  min-height: 90vh;
  padding-top: 10vh;
}

.avatarbis {
  width: 15vw;
  max-width: 400px;
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 1rem;
}

.avatarbis:hover {
  filter: opacity(0.5);
}

.Home-title {
  font-size: 2.1rem;
  text-decoration: none;
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.App-homepage-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}

.Home-title.dark {
  color: white;
}

/* Typewriter Animation */
.line-1 {
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-0%);
}

.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 99%;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: #333;
  }
  to {
    border-right-color: transparent;
  }
}
/* Typewriter Animation end */

/**************************************************************** STACK */

.Stack-entire {
  padding: 20px;
  text-align: center;
}

.Section-title {
  margin-bottom: 20px;
}

.tech-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  color: black;
}

.tech-category {
  background-color: #f0ebe7;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tech-category-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.techs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tech-item {
  background-color: #ffffff;
  border: 2px solid #41403e; /* Border style similar to your button */
  border-radius: 15px 225px 255px 15px / 255px 15px 225px 15px; /* Adjusted border radius */
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  padding: 10px 15px; /* Adjust padding */
  font-size: 0.9rem;
  color: #41403e;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tech-item:hover {
  background-color: black;
  color: white;
  border-color: black;
  transform: translateY(-2px);
}

.tech-item:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}

/**************************************************************** WORK */

h5 {
  text-align: center;
  margin-bottom: 2px;
  color: black;
}

.content {
  padding: 1rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  width: 90%;
}
.content h4 {
  text-align: center;
}
.choresapp {
  background-color: white;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #41403e;
}

#comingsoon {
  color: black;
  font-style: italic;
}

.container {
  width: 100vw;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.container .card {
  position: relative;
}

.container .card .face {
  width: 450px;
  height: 500px;
  transition: 0.4s;
}

.container .card .face.face1 {
  position: relative;
  inset: 0;
  background: #eae2dd;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(180px);
  color: black;
}

.container .card:hover .face.face1 {
  background: #eae2dd;
  transform: translateY(0);
}

.container .card .face.face1 .content {
  opacity: 1;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .card .face.face1 .content img {
  max-width: 100vw;
  width: 18rem;
}

.container .card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: black;
  text-align: center;
  font-size: 1.5em;
}

.container .card .face.face2 {
  position: relative;
  height: 400px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 2rem;
  /* padding-top: 4rem; */
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-220px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  margin: 0;
  padding: 0;
}

.container .card .face.face2 .content a {
  margin: 4px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.container .card .face.face2 .content a:hover {
  background: #333;
  color: #fff;
}

.projectTasks {
  font-size: 0.7rem;
  color: black;
}

/* .projectButton {
  font-size: 0.1em;
} */

/**************************************************************** ABOUT PAGE */

.AboutmeParagraph {
  text-align: justify;
  padding: 1rem;
}

.AboutmeContent {
  display: flex;
  justify-content: center;
}

.AboutmeBox {
  margin: 1rem;
  /* background-color: #fff; */
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  /* color: #41403e; */
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 23px;
  padding: 1.5rem;
  transition: all 235ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
}

.AboutmeBox:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.AboutmeBox:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
}

.AboutmeText {
  width: 100%;
  height: 100%;
}

/**************************************************************** REVIEWS PAGE */

.ReviewParagraph {
  text-align: justify;
  padding: 1rem;
}
.ReviewContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ReviewText {
  width: 65vw;
  height: 100%;
}

.reviews-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.review-card {
  background-color: #f0ebe7;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40rem;
  height: 30vh;
  max-width: 90%;
  margin-bottom: 1rem;
  text-align: center;
  color: black;
  overflow-y: scroll;
}

.review-card h2 {
  margin-bottom: 5px;
}

.position {
  color: #393838;
  margin-top: -10px;
  margin-bottom: 10px;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.dot.active {
  background-color: #0073b1;
}

/**************************************************************** CONTACT */

.Section-title.text-2xl {
  font-size: 2rem;
}

.Section-title.text-md {
  font-size: 2rem;
}

textarea:focus,
.name-input:focus {
  outline: none;
}

.input {
  width: 30vw;
  border: 1px, black;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: black;
  background-color: white;
  margin: 1rem;
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#mail {
  margin: 1rem;
}

#message {
  height: 20vh;
  margin: 1rem;
}

.input-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-bottom: 1rem;
}

.button-55 {
  margin: 1rem;
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
  background-color: black;
  color: white;
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
}

/****************************************************************  FOOTER & DARK MODE TOGGLE */

.Footer-entire {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
}

.Footer-toggle {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #dfdfdf; */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: transparent;
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -5px 0 0 #a3dafb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: transparent;
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

input:checked + .slider:before {
  background-color: #a9c9ff;
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/****************************************************************  MEDIA QUERIES */

@media (max-width: 900px) {
  /********* NAVBAR */

  .Navbar {
    min-width: 20vw;
  }
  /********* WORK */

  .projectTasks {
    font-size: 0.8rem;
  }

  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-item {
    width: 70vw;
    height: 100%;
  }

  .container .card .face {
    width: 100vw;
    height: 25rem;
    transition: 0s;
  }
  .container .card .face.face1 {
    position: relative;
    inset: 0;
    background: #eae2dd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(180px);
  }

  .container .card .face.face1 .content {
    opacity: 1;
  }

  .container .card .face.face2 {
    position: relative;
    height: 25rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 2rem;
    padding-top: 8rem;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(3rem);
  }

  .container .card:hover .face.face1 .content {
    opacity: 1;
  }

  .container .card:hover .face.face1 {
    position: relative;
    inset: 0;
    background: #eae2dd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(180px);
  }

  .container .card:hover .face.face2 {
    position: relative;
    height: 25rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 2rem;
    padding-top: 8rem;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(3rem);
  }

  /********* CONTACT */

  .input {
    width: 60vw;
  }
}

@media (max-width: 700px) {
  /********* NAVBAR */

  .Navbar {
    display: none;
  }

  .header-with-nav {
    display: flex;
    flex-direction: column;
  }

  .Navburger {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 15vh;
    justify-content: space-around;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    position: fixed;
    height: 25vh;
    width: 25vw;
    right: 0;
    /* margin-right: 5vw; */
    z-index: 199;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  .Navburger:hover {
    cursor: pointer;
  }

  .Navburger.dark {
    background-color: black;
  }

  .Navburger.light {
    background-color: white;
  }

  .App-work-section {
    width: 95vw;
  }

  /* .About-me-paragraph {
    width: 90vw;
  } */

  .hamburger {
    width: 2rem;
    height: 1.6rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    cursor: pointer;
  }

  .burger {
    width: 1.6rem;
    height: 0.15rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }

  .burger.light {
    background-color: black;
  }

  .burger.dark {
    background-color: white;
  }

  .header-display {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90vw;
  }

  /* mouse over link */
  .burger-nav:hover {
    color: #9e9e9e;
  }
  /* selected link */
  .burger-nav :active {
    color: #9e9e9e;
  }

  .firstNavLink {
    margin-top: 1rem;
  }

  /********* HOMEPAGE */

  .Section-title {
    font-size: 2rem;
    padding-top: 20vh;
  }

  .App-section {
    height: 100%;
    min-height: 90vh;
  }

  .App-homepage {
    flex-direction: column;
  }

  .avatarbis {
    width: 35vw;
    margin-top: 15vh;
  }

  .Home-title {
    font-size: 2.1rem;
    text-decoration: none;
    text-align: center;
  }

  .socials-dark {
    filter: invert(1);
    width: 1rem;
    padding: 0.8rem;
  }

  .socials-light {
    filter: invert(0);
    width: 1rem;
    padding: 0.8rem;
  }

  /********* ABOUT */

  /* .AboutmeContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .AboutmePhoto img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .AboutmePhoto:hover img {
    filter: opacity(1);
  }

  .AboutmeText {
    width: 100vw;
    height: 100%;
  } */

  /********* WORK*/
  .projectTasks {
    font-size: 0.4rem;
  }

  /********* CONTACT*/
  .input {
    width: 70vw;
  }
}

@media (max-width: 410px) {
  .hexagontent {
    font-size: 0.5rem;
    font-weight: bolder;
  }

  .hexagon,
  .hexagon::before,
  .hexagon::after {
    width: 40px;
    height: 65px;
  }
}

@media (max-width: 375px) {
  .hexagontent {
    font-size: 0.8rem;
  }
  .hexagon,
  .hexagon::before,
  .hexagon::after {
    /* easy way: height is width * 1.732
  actual formula is 2*(width/(2*Math.tan(Math.PI/6)))
  remove border-radius for sharp corners on hexagons */
    width: 67px;
    height: 116px;
    /* border-radius: 20%/5%; */
  }
}
